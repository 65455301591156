<template>
  <div class="toggle_container">
    <label class="control-label"> {{ label }}</label>
    <label
      :for="id + '_button'"
      :class="{ active: isActive }"
      class="toggle__button"
    >
      <span v-if="isActive" class="toggle__label">{{ enableText }}</span>
      <span v-if="!isActive" class="toggle__label">{{ disabledText }}</span>

      <input
        :id="id + '_button'"
        v-model="checkedValue"
        type="checkbox"
        :disabled="disabled"
      />
      <span class="toggle__switch"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    labelEnableText: {
      type: String,
      default: 'On',
    },

    labelDisableText: {
      type: String,
      default: 'Off',
    },
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: 'primary',
    },

    defaultState: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentState: this.defaultState,
    };
  },

  computed: {
    // currentState() {
    //     return this.defaultState;
    // },

    isActive() {
      return this.currentState;
    },

    enableText() {
      return this.labelEnableText;
    },

    disabledText() {
      return this.labelDisableText;
    },

    checkedValue: {
      get() {
        return this.currentState;
      },

      set(newValue) {
        this.currentState = newValue;
        this.$emit('change', newValue);
      },
    },
  },

  watch: {
    defaultState: function defaultState() {
      this.currentState = Boolean(this.defaultState);
    },
  },
};
</script>

<style scoped>
.toggle_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.toggle__button {
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  margin: 5px auto;
}

.toggle__button input[type='checkbox'] {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
}

.toggle__button .toggle__switch {
  display: inline-block;
  height: 12px;
  border-radius: 6px;
  width: 40px;
  background: #bfcbd9;
  box-shadow: inset 0 0 1px #bfcbd9;
  position: relative;
  margin-left: 10px;
  transition: all 0.25s;
}

.toggle__button .toggle__switch::after,
.toggle__button .toggle__switch::before {
  content: '';
  position: absolute;
  display: block;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  left: 0;
  top: -3px;
  transform: translateX(0);
  transition: all 0.25s cubic-bezier(0.5, -0.6, 0.5, 1.6);
}

.toggle__button .toggle__switch::after {
  background: #4d4d4d;
  box-shadow: 0 0 1px #666;
}

.toggle__button .toggle__switch::before {
  background: #4d4d4d;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
  opacity: 0;
}

.active .toggle__switch {
  background: #303e64;
  box-shadow: inset 0 0 1px #303e64;
}

.active .toggle__switch::after,
.active .toggle__switch::before {
  transform: translateX(40px -18px);
}

.active .toggle__switch::after {
  left: 23px;
  background: #d650d7;
  box-shadow: 0 0 1px #d650d7;
}
</style>
