<template>
  <transition
    name="photos-gallery-transition"
    enter-active-class="animate__animated animate__fadeInUp animate__faster"
    leave-active-class="animate__animated animate__fadeOutDown animate__faster"
    @afterLeave="reset"
  >
    <div v-if="isActive" class="photos-gallery">
      <div class="photos-gallery-close" @click="isActive = false">
        <!-- <font-awesome-icon icon="times" /> -->
        X
      </div>
      <div class="photos-gallery-arrows">
        <div
          class="photos-gallery-arrow is-previous"
          @click="showPreviousPhoto"
        >
          <!-- <font-awesome-icon icon="angle-left" /> -->
        </div>
        <div class="photos-gallery-arrow is-next" @click="showNextPhoto">
          <!-- <font-awesome-icon icon="angle-right" /> -->
        </div>
      </div>
      <div class="photos-gallery-photos-list">
        <img
          v-for="photo in renderedPhotos"
          :key="photo.id"
          :src="photo.src"
          :class="{ 'is-visible': currentPhoto.id === photo.id }"
        />
      </div>
      <div
        class="photos-gallery-counter"
        v-text="`${currentIndex + 1} / ${photos.length}`"
      />
      <div v-if="loading" class="photos-gallery-loading-overlay">
        <!-- <font-awesome-icon icon="circle-notch" spin /> -->
      </div>
    </div>
  </transition>
</template>

<script>
import findIndex from 'lodash/findIndex';
import {ASSETS_URL, PhotosGalleryEvents} from '@/utils/constants';

export default {
  name: 'PhotosGallery',

  data: () => ({
    isActive: false,
    photos: [],
    currentIndex: -1,
    canEdit: false,
    requestId: '',
    renderedIndexes: [],
    mainPhotoId: null,
    loading: false,
  }),
  computed: {
    renderedPhotos() {
      return this.renderedIndexes.map((index) => ({
        ...this.photos[index],
        src: `${ASSETS_URL}${this.photos[index].largePhoto}`,
      }));
    },
    currentPhoto() {
      return this.photos[this.currentIndex];
    },
  },

  watch: {
    currentIndex(newIndex, oldIndex) {
      if (newIndex === -1) {
        this.renderedIndexes = [];
        return false;
      }

      // if it is equal it means that the last photo was removed and updating indexes responsability falls to the delete method
      if (oldIndex !== this.photos.length) {
        this.updateRenderedIndexes(newIndex, oldIndex);
      }
    },
  },

  mounted() {
  },

  methods: {
    open({
           photos = [],
           photoId = null,
           canEdit = false,
           requestId = '',
           mainPhotoId = null,
         } = {}) {
      if (photos.length === 0) return false;
      if (photoId !== null) {
        const index = findIndex(photos, ['id', photoId]);
        this.currentIndex = Math.max(index, 0);
      }
      this.photos = photos;
      this.canEdit = canEdit;
      this.requestId = requestId;
      this.mainPhotoId = mainPhotoId;
      this.isActive = true;
    },

    reset() {
      this.photos = [];
      this.currentIndex = -1;
      this.requestId = '';
      this.canEdit = false;
      this.mainPhotoId = null;
    },

    updateRenderedIndexes(newIndex, oldIndex) {
      const photosLength = this.photos.length;
      if (photosLength <= 3) {
        this.renderedIndexes = [0, 1, 2].slice(0, photosLength);
        return false;
      }

      if (this.renderedIndexes.length === 0) {
        this.renderedIndexes = [
          this.getPreviousIndex(),
          this.currentIndex,
          this.getNextIndex(),
        ];
        return false;
      }

      const renderedIndexes = [newIndex, oldIndex];
      const changeableValue = this.renderedIndexes.filter(
        (index) => !renderedIndexes.includes(index)
      )[0];
      const changeableIndex = this.renderedIndexes.indexOf(changeableValue);
      if (
        newIndex > oldIndex ||
        (oldIndex === this.photos.length - 1 && oldIndex > newIndex)
      ) {
        // to the next photo, should preload next index
        this.renderedIndexes.splice(changeableIndex, 1, this.getNextIndex());
      } else {
        this.renderedIndexes.splice(
          changeableIndex,
          1,
          this.getPreviousIndex()
        );
      }
    },
    getNextIndex() {
      let index = this.currentIndex + 1;
      if (index >= this.photos.length) index = 0;
      return index;
    },
    getPreviousIndex() {
      let index = this.currentIndex - 1;
      if (index < 0) index = this.photos.length - 1;
      return index;
    },
    showNextPhoto() {
      this.currentIndex = this.getNextIndex();
    },
    showPreviousPhoto() {
      this.currentIndex = this.getPreviousIndex();
    },
  },
};
</script>

<style lang="sass">
.photos-gallery
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100vh
  background-color: #1c1917
  z-index: 1000

  .photos-gallery-close
    position: absolute
    top: 0px
    right: 0px
    padding: 15px
    font-size: 24px
    color: #cbd5e1
    z-index: 3
    cursor: pointer

    .photos-gallery-arrows
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      display: flex
      z-index: 2

  .photos-gallery-arrow
    padding: 15px
    display: flex
    align-items: center
    font-size: 24px
    color: #cbd5e1
    cursor: pointer

    &.is-previous
      flex: 0 0 40%
      text-align: left

    &.is-next
      flex: 1
      justify-content: flex-end

    svg
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, .7))

    .photos-gallery-photos-list
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      z-index: 1
      display: flex
      align-items: center
      justify-content: center

  img
    max-width: 100%
    max-height: 100%
    display: block

    &:not(.is-visible)
      position: absolute
      opacity: 0

    .photos-gallery-counter
      position: absolute
      bottom: 15px
      right: 15px
      background-color: rgba(black, .5)
      color: #fafafa
      padding: 0 15px
      border-radius: 17px
      text-align: center
      height: 34px
      line-height: 34px

    .photos-gallery-actions
      position: absolute
      top: 15px
      left: 15px
      display: flex

  .photos-gallery-action
    background-color: rgba(black, .5)
    color: #fafafa
    padding: 0 15px
    border-radius: 17px
    text-align: center
    height: 34px
    line-height: 34px
    z-index: 4
    font-size: 14px

    &:not(:first-child)
      margin-left: 15px

      svg
        margin-right: 5px

    .photos-gallery-loading-overlay
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      z-index: 5
      background-color: rgba(black, .5)
      color: #fafafa
      display: flex
      align-items: center
      justify-content: center
</style>
