<template>
  <div class="row">
    <div class="col-12">
      <card class="card-plain">
        <div
          class="table-full-width table-responsive"
          v-if="table.data.length !== 0"
        >
          <base-table
            :title="table.title"
            :sub-title="table.subtitle"
            :data="table.data"
            :columns="table.columns"
            :hasButton="true"
            :buttonText="'Details'"
            :linkTo="{ path: '/profile', query: 'username' }"
            :hasLink="true"
          >
          </base-table>
        </div>
        <div v-else>
          LOADING
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import {BaseTable} from '@/components';

const tableColumns = ['id', 'username', 'city', 'isVerified'];

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      table: {
        title: 'Table on Plain Background',
        columns: [...tableColumns],
        data: [],
      },
    };
  },
  methods: {
    async getAccounts() {
      try {
        const response = await this.$axios.get('Account/All');
        this.table.data = response.data.slice(0, 10);
      } catch (err) {
        console.log('TableList - getAccounts query fail', e);
      }
    },
  },
  mounted() {
    this.getAccounts();
  },
};
</script>
<style></style>
