<template>
  <div class="row">
    <div id="app">
      <modal
        v-if="showData"
        :show.sync="showData"
        :centered="false"
        :show-close="true"
        class="auditData-modal"
      >
        <card
          title="Audit data details"
          type="primary"
          slot="header"
          headerClasses="test-class"
          id="auditData-card-modal"
          class="card-plain"
        >
          {{ table.data[itemIndex].auditData }}
        </card>
      </modal>

      <div class="filters-parent">
        <date-picker
          class="mx-calendar mx-calendar-panel-date filters-child"
          @change="changed"
          @clear="clear"
          v-model="time"
          range
          format="YYYY-MM-DD"
        />
        <base-input
          addonLeftIcon="tim-icons icon-zoom-split"
          type="text"
          placeholder="Filter results"
          v-model="filter"
          class="filters-child search-input"
        >
        </base-input>

        <base-dropdown
          class="filters-child page-size-dropdown"
          title-classes="btn btn-secondary"
          title="Select page size"
        >
          <a @click="setPageSize(5)" class="dropdown-item" href="#"
          >5</a
          >
          <a @click="setPageSize(10)" class="dropdown-item" href="#"
          >10</a
          >
          <a @click="setPageSize(20)" class="dropdown-item" href="#"
          >20</a
          >
          <a @click="setPageSize(30)" class="dropdown-item" href="#"
          >30</a
          >
        </base-dropdown>

        <base-dropdown
          class="filters-child page-size-dropdown"
          title-classes="btn btn-secondary"
          :title="searchType"
        >
          <a @click="setSearchType('action')" class="dropdown-item" href="#"
          >Action</a
          >
          <a @click="setSearchType('entityId')" class="dropdown-item" href="#"
          >EntityID</a
          >
          <a @click="setSearchType('entityType')" class="dropdown-item" href="#"
          >EntityType</a
          >
        </base-dropdown>
      </div>
    </div>
    <div class="col-12">
      <card class="card-plain">
        <div
          class="table-full-width table-responsive"
          v-if="table.data.length !== 0"
        >
          <base-table
            :title="table.title"
            :sub-title="table.subtitle"
            :data="table.data"
            :columns="table.columns"
            :hasExpandData="true"
            :buttonText="'Expand'"
            :expandData="expandData"
            :onPageChange="onPageChange"
            :currentPage="currentPage"
          >
          </base-table>
        </div>
        <div v-else>No results found</div>
      </card>
    </div>
  </div>
</template>
<script>
import {BaseTable} from '@/components';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {format} from 'date-fns';
import '@/assets/sass/audit.scss';
import {CollapseTransition} from 'vue2-transitions';
import Modal from '@/components/Modal';

const tableColumns = [
  'id',
  'entityId',
  'entityType',
  'auditAction',
  'auditDate',
];

export default {
  components: {BaseTable, DatePicker, CollapseTransition, Modal},

  data() {
    return {
      searchType: 'Select filter type',
      itemIndex: null,
      time: null,
      filter: '',
      showData: false,
      showModal: false,
      table: {
        title: 'Audit Logs Table',
        columns: [...tableColumns],
        data: [],
      },
      currentPage: 0,
      queryParams: {
        entityType: 'entityType=',
        entityId: 'entityId=',
        action: 'action=',
        startDate: '',
        endDate: '',
        page: 0,
        size: 5,
      },
    };
  },

  watch: {
    filter() {
      this.getLogs();
    },
  },
  methods: {
    async getLogs() {
      try {
        this.queryParams.startDate = this.time ? this.formatDate(this.time[0]) : '';
        this.queryParams.endDate = this.time ? this.formatDate(this.time[1]) : '';

        this.setParamValue();

        const logs = await this.$axios.get(
          `
						admin/auditLog?
						number=${this.queryParams.size}&
						${this.queryParams.entityType}&
						startDate=${this.queryParams.startDate}&
						endDate=${this.queryParams.endDate}&
						page=${this.queryParams.page}&
						${this.queryParams.entityId}&
						${this.queryParams.action}
					`
        );
        const {auditLogs} = logs.data;
        const logsArray = Object.values(auditLogs).map(element => element);
        this.table.data = logsArray;
      } catch (e) {
        console.log('auditLogs - getLogs query fail', e);
      }
    },

    setSearchType(type) {
      this.searchType = type;
      this.getLogs();
    },
    setPageSize(size) {
      this.queryParams.size = size;
      this.getLogs();
    },
    clear() {
      this.time = null;
      this.getLogs();
    },
    changed() {
      this.queryParams.startDate = this.time ? this.formatDate(this.time[0]) : '';
      this.queryParams.endDate = this.time ? this.formatDate(this.time[1]) : '';
      this.getLogs();
    },
    onPageChange(page) {
      this.currentPage = page;
      this.queryParams.page = this.currentPage;
      this.getLogs();
    },
    expandData(index) {
      if (this.table.data.length > 0) {
        this.showData = !this.showData;
        this.itemIndex = index;
      }
    },
    formatDate(date, type = 'yyyy-MM-dd') {
      return format(new Date(date), type);
    },
    setParamValue() {
      switch (this.searchType) {
        case 'entityId':
          this.queryParams.entityId = `entityId=${this.filter}`
          break;
        case 'action':
          this.queryParams.action = `action=${this.filter}`;
          break;
        case 'entityType':
          this.queryParams.entityType = `entityType=${this.filter}`;
          break;
        default:
          console.log('Invalid or null searchType');
      }
    }
  },

  mounted() {
    this.getLogs();
  },
};
</script>
<style></style>
