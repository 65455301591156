<template>
  <div class="row" v-if="!loading">
    <div class="col-md-8">
      <edit-profile-form :model="model"></edit-profile-form>
    </div>
    <div class="col-md-4">
      <user-card :user="user"></user-card>
    </div>
    <card>
      <thumbnail-gallery
        :photos="model.photos"
        @show="showPhoto"
      ></thumbnail-gallery>
    </card>
    <photos-gallery ref="photosGallery"></photos-gallery>
  </div>
  <div v-else>Loading</div>
</template>
<script>
import EditProfileForm from './Profile/EditProfileForm';
import UserCard from './Profile/UserCard';
import PhotosGallery from '../components/PhotoGallery.vue';
import ThumbnailGallery from '../components/ThumbnailGallery.vue';

export default {
  components: {
    EditProfileForm,
    UserCard,
    PhotosGallery,
    ThumbnailGallery,
  },
  data() {
    return {
      model: {
        id: 0,
        username: '',
        email: '',
        accountTypeId: null,
        mainPhotoId: null,
        created: '',
        updated: null,
        isVerified: null,
        county: '',
        city: '',
        country: '',
        countryCode: '',
        latitude: null,
        longitude: null,
        description: null,
        telephone: null,
        rating: 0,
        reviews: [],
        services: [],
        photos: [],
        estimatedCounty: null,
        acceptedCookiesPolicy: null,
        allowSearchEngine: null,
        lastRefresh: null,
        hasCompletedProfile: null,
        hasWhatsapp: null,
        userDetails: [
          {
            astrologySignTypeId: null,
            birthDate: '',
            drinkerTypeId: null,
            exerciseTypeId: null,
            eyeColorId: null,
            genderId: null,
            hairColorId: null,
            height: null,
            id: 0,
            orientationId: null,
            religionTypeId: null,
            smokerTypeId: null,
            userId: 0,
            weight: null,
          },
        ],
      },
      loading: true,
      user: {
        username: '',
        title: '',
        description: ``,
        profilePhoto: '',
      },
    };
  },

  mounted() {
    this.$axios
      .get(`Account/profile?username=${this.$route.query.username}`)
      .then((response) => {
        this.loading = false;
        this.model = {
          ...response.data,
        };
        this.user = {
          username: response.data.username,
          description: response.data.description,
          title: this.accountType,
          profilePhoto:
            'https://cdn.intalniri24.ro/' +
            response.data.photos.filter(
              (photo) => photo.id === response.data.mainPhotoId
            )[0].thumbnail,
        };
      })
      .catch((err) => {
        console.log(err);
      });
  },

  computed: {
    accountType() {
      const accountTypes = this.$store.getters['constants/keyedAccountTypes'];
      const userAccountType = this.model.accountTypeId;
      return accountTypes[userAccountType].name;
    },
  },

  methods: {
    showPhoto({photoId = null} = {}) {
      if (photoId === null) photoId = this.model.mainPhotoId;
      if (photoId === null) return false;

      this.$refs.photosGallery.open({
        photoId,
        photos: this.model.photos,
        mainPhotoId: this.model.mainPhotoId,
      });
    },
  },
};
</script>
<style></style>
