<template>
  <div class="row">
    <div class="col-md-3 pr-md-3">
      <base-dropdown
        title-classes="btn btn-secondary btn-custom"
        :title="gender"
        v-model="genderId"
        label="Gender"
      >
        <li
          class="dropdown-item"
          v-for="gender in genders"
          :key="gender.id"
          @click="setNewGender(gender)"
        >
          {{ gender.title }}
        </li>
      </base-dropdown>
    </div>
    <div class="col-md-3 pl-md-3">
      <base-dropdown
        title-classes="btn btn-secondary btn-custom"
        :title="orientation"
        v-model="orientationId"
        label="Orientation"
      >
        <li
          class="dropdown-item"
          v-for="orientation in orientations"
          :key="orientation.id"
          @click="setNewOrientation(orientation)"
        >
          {{ orientation.title }}
        </li>
      </base-dropdown>
    </div>
    <div class="col-md-3 pl-md-3">
      <label for="birthDate" class="control-label">Birth date:</label>
      <input
        type="date"
        id="birthDate"
        name="Birth Date"
        v-model="birthDate"
        class="date-picker"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserDetailsForm',

  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      astrologySignTypeId: null,
      birthDate: '',
      drinkerTypeId: null,
      exerciseTypeId: null,
      eyeColorId: null,
      genderId: null,
      hairColorId: null,
      height: null,
      id: 0,
      orientationId: null,
      religionTypeId: null,
      smokerTypeId: null,
      userId: 0,
      weight: null,
    };
  },

  methods: {
    setNewGender(gender) {
      this.genderId = gender.id;
    },
    setNewOrientation(orientation) {
      this.orientationId = orientation.id;
    },
  },
  computed: {
    genders() {
      const genders = [...this.$store.getters['constants/formattedGenders']];
      return genders.map((gender) => {
        gender.title = this.$t(gender.localeKey);
        return gender;
      });
    },

    orientations() {
      const orientations = [
        ...this.$store.getters['constants/formattedOrientations'],
      ];
      return orientations.map((orientation) => {
        orientation.title = this.$t(orientation.localeKey);
        return orientation;
      });
    },

    gender() {
      const gender = this.genders.find((gender) => {
        return gender.id === this.genderId;
      });

      return gender.title;
    },

    orientation() {
      const orientation = this.orientations.find((orientation) => {
        return orientation.id === this.orientationId;
      });

      return orientation.title;
    },
  },
  watch: {
    model: {
      immediate: true,
      handler(model) {
        Object.assign(this, {
          ...model,
          birthDate: model.birthDate.substring(0, 10),
        });
      },
    },
  },
};
</script>

<style></style>
