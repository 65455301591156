<template>
  <div class="profile-photos">
    <div v-if="canEdit" class="add-photo-action" @click="add">
      <font-awesome-icon icon="plus"/>
      <span v-text="$t('addPhoto')"/>
    </div>
    <div
      v-for="photo in photos"
      :key="photo.id"
      class="photo"
      @click="$emit('show', { photoId: photo.id })"
    >
      <img :src="`${assetsUrl}${photo.thumbnail}`" alt="profile photo"/>
    </div>
    <div
      v-if="photos.length === 0"
      class="no-photos"
      v-text="
        $t(`noPhoto.${canEdit ? 'myProfile' : 'otherProfile'}`, {
          name,
        })
      "
    ></div>
  </div>
</template>

<script>
import last from 'lodash/last';
import {ASSETS_URL} from '@/utils/constants';

export default {
  name: 'ProfilePhotos',
  props: {
    photos: {
      type: Array,
      default: () => [],
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
  },
  computed: {
    assetsUrl() {
      return ASSETS_URL;
    },
  },
  tempPhoto: null,
  methods: {},
  i18n: {
    messages: {
      ro: {
        addPhoto: 'Adaugă poză',
        noPhoto: {
          myProfile: 'Nu ai adăugat nicio poză',
          otherProfile: '{name} nu a adăugat nicio poză',
        },
        fileExtensionError: 'Sunt permise doar poze PNG sau JPG',
        failed: {
          title: 'A apărut o eroare neașteptată',
          text:
            'Te rugăm să ne contactezi la <a href="mailto:contact@intalniri24.ro">contact@intalniri24.ro</a>',
        },
      },
    },
  },
};
</script>

<style lang="sass">
.profile-photos
  display: flex
  flex-wrap: wrap
  padding: 10px
  width: 100%

  .no-photos
    color: #52525b
  .photo
    padding: 10px
    position: relative
    cursor: pointer
    flex: 0 0 25%

  img
    display: block
    max-width: 100%
    border-radius: 8px
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)

  .remove-action
    position: absolute
    top: 15px
    right: 15px
    font-size: 10px
    color: white
    background-color: red
    border-radius: 15px
    padding: 4px 8px
</style>
