<template>
  <card>
    <h5 slot="header" class="title">Edit Profile</h5>
    <div class="row">
      <div class="col-md-2 pr-md-1">
        <base-input
          label="Age"
          placeholder="Age"
          v-model="model.age"
          :isDisabled="true"
        >
        </base-input>
      </div>
      <div class="col-md-5 px-md-1">
        <base-input
          label="Username"
          placeholder="Username"
          v-model="model.username"
        >
        </base-input>
      </div>
      <div class="col-md-4 pl-md-1">
        <base-input
          label="Email address"
          type="email"
          placeholder="to be updated"
          v-model="model.email"
        >
        </base-input>
      </div>
    </div>
    <div class="row" v-for="details in model.userDetails" :key="details.id">
      <UserDetailsForm
        :model="details"
        @change="(newValue) => handleToggle(newValue, 'birthDate')"
      ></UserDetailsForm>
    </div>
    <div class="row">
      <div class="col-md-3 pl-md-3">
        <BaseToggle
          label="Verified"
          label-enable-text="Yes"
          label-disable-text="No"
          id="Verified"
          :default-state="model.isVerified"
          @change="(newValue) => handleToggle(newValue, 'isVerified')"
        ></BaseToggle>
      </div>
      <div class="col-md-3 pl-md-3">
        <BaseToggle
          label="Cookies"
          label-enable-text="Yes"
          label-disable-text="No"
          id="Cookies"
          :default-state="model.acceptedCookiesPolicy"
          @change="
            (newValue) => handleToggle(newValue, 'acceptedCookiesPolicy')
          "
        ></BaseToggle>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 pl-md-3">
        <BaseToggle
          label="Search Engine"
          label-enable-text="Yes"
          label-disable-text="No"
          id="Search Engine"
          :default-state="model.allowSearchEngine"
          @change="(newValue) => handleToggle(newValue, 'allowSearchEngine')"
        ></BaseToggle>
      </div>
      <div class="col-md-3 pl-md-3">
        <BaseToggle
          label="Complete Profile"
          label-enable-text="Yes"
          label-disable-text="No"
          id="Complete Profile"
          :default-state="model.hasCompletedProfile"
          @change="(newValue) => handleToggle(newValue, 'hasCompletedProfile')"
        ></BaseToggle>
      </div>
      <div class="col-md-3 pl-md-3">
        <BaseToggle
          label="Whatsapp"
          label-enable-text="Yes"
          label-disable-text="No"
          id="Whatsapp"
          :default-state="model.hasWhatsapp"
          @change="(newValue) => handleToggle(newValue, 'hasWhatsapp')"
        ></BaseToggle>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7">
        <base-input
          label="Address"
          v-model="model.address"
          placeholder="Home Address"
        >
        </base-input>
      </div>
      <div class="col-md-5 ">
        <base-input
          label="Last Refresh"
          v-model="model.lastRefresh"
          placeholder="Last Refersh"
          :isDisabled="true"
        >
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 pr-md-1">
        <base-input label="City" v-model="model.city" placeholder="City">
        </base-input>
      </div>
      <div class="col-md-4 px-md-1">
        <base-input
          label="Country"
          v-model="model.country"
          placeholder="Country"
        >
        </base-input>
      </div>
      <div class="col-md-4 pl-md-1">
        <base-input
          label="County"
          placeholder="County code"
          v-model="model.county"
        >
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <base-input>
          <label class="control-label">Description</label>
          <textarea
            rows="4"
            cols="80"
            class="form-control"
            v-model="model.description"
          >
          </textarea>
        </base-input>
      </div>
    </div>
    <base-button slot="footer" type="primary" fill @click="save"
    >Save
    </base-button
    >
  </card>
</template>
<script>
import BaseDropdown from '../../components/BaseDropdown.vue';
import BaseToggle from '../../components/BaseToggle.vue';
import UserDetailsForm from './UserDetailsForm.vue';

export default {
  components: {BaseDropdown, BaseToggle, UserDetailsForm},
  name: 'EditProfileForm',

  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {};
  },

  mounted() {
    console.log(this.model);
  },

  methods: {
    save() {
      console.log(this.model);
      //   this.$axios.patch(`admin/user`, this.model).then((response) => {
      //     console.log(response);
      //   });
    },

    handleToggle(value, property) {
      if (property === 'birthDate') {
        this.model[property] = value.target.value;
      }
      this.model[property] = value;
    },
  },
};
</script>
<style>
.btn-custom {
  width: 160px;
}

.date-picker {
  color: #ca52e1;
  border: 1px solid #d650d7;
  background: #303e65;
  padding: 5px;
}

::-webkit-calendar-picker-indicator {
  color: #ca52e1;
}
</style>
